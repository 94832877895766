<template>
  <div class="puzzle">
    <div v-if="choice==1">
      <img v-if="place" src="../assets/1.png" @click="clicked" class="img" alt="">
    </div>
    <div v-if="choice==2">
      <img v-if="place" src="../assets/2.png" @click="clicked" class="img" alt="">
    </div>
    <div v-if="choice==3">
      <img v-if="place" src="../assets/4.jpg" @click="clicked" class="img" alt="">
    </div>
    <div v-if="choice==4">
      <img v-if="place" src="../assets/5.jpg" @click="clicked" class="img" alt="">
    </div>
  </div>
</template>

<script>

export default {
  name : "Puzzle",
  props : ["place","choice"],
  data(){
    return{
      images : ['../assets/1.png' , '../assets/2.png']
    }
  },
  methods : {
    clicked(){
      this.$emit('clicked')
    }
  },
  updated(){
  }
}
</script>

<style>
.puzzle{
    width: 100px;
    height: 100px;
    margin: 10px 10px;
    flex-basis: 1;
    border-radius: 5px;
}
.img{
  border-radius: 5px;
  width: 100px;
  height: 100px;
}
@media (max-width: 600px) {
  .puzzle{
    width: 50px;
    height: 50px;
  }
  .img{
      width: 50px;
  height: 50px;
  }
}
</style>