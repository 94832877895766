<template>
<div class="header">
  <h1>Jad Alik</h1>
  <button @click="play" :disabled="isStarted"> play</button>
</div>
  <div v-if="isStarted" class="game">

    <Puzzle v-for="i in range" :key="i" :place="i==random" @clicked="finished" :choice="choice"/>
  </div>
      <h1 v-if="!isStarted" >{{score}}</h1>
      <h1 style="display :none" >{{round}}</h1>
      
</template>


<script>
import Puzzle from './components/puzzle.vue'

export default {
  name: 'App',
  components: {Puzzle, },
  data(){
    return{
      range : [],
      isStarted : false,
      random : 0,
      score : 0,
      round : 0,
      game : 0,
      stopRound : 0,
      stopGame : 0,
      stopPlay : 0,
      choice : 2
    }
  },
  mounted(){
    var range = n => Array(n + 1).join(1).split('').map((x, i) => i)
    this.range = range(25)
    this.random = this.between(0,24)
  },
  methods:{
    roundTime(){
      this.stopRound = setInterval(() => {
        this.round+=100
      }, 100);
    },
    randomize(){
      this.random = this.between(0,24)
    },
    play(){
      this.stopPlay = setTimeout(() => {
        this.score = 0
        this.isStarted = true
        this.roundTime()
        this.gameTime()
      }, 1500);
    },
    between(min, max) {  
      return Math.floor(Math.random() * (max - min) + min)
    },
    finished(){
      this.score+=1
      this.randomize()
      this.choice = this.between(1,5)
      console.log(this.choice)
      this.round = 0
    },
    gameTime(){
      this.stopGame = setInterval(() => {
        this.game+=100
      }, 100);
    }
  },
  updated(){
    if(this.game<15000){
      if(this.round>=1000){
        this.randomize()
        this.round = 0
        this.choice = this.between(1,6)
      }
    }else{
      this.isStarted = false
      this.game = 0
      this.round = 0
      clearTimeout(this.stopRound)
      clearTimeout(this.stopGame)
      clearTimeout(this.stopPlay)
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.header{
width: 100vw;
margin: 0 auto;
}
h1{
  color: #000;
  font-size: 50px;
  margin: 0;
}

button{
  background: black;
  color:white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin:10px;
}

button[disabled]{
  opacity: 0.2;
  cursor:not-allowed;
}
.game{
  max-width: 600px;
  max-height: 600px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  border: 1px solid black;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

</style>
